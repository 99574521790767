<template>
  <div class="home">
    <NavBar></NavBar>
    <games msg="Choisis un jeu" />
  </div>
</template>

<script>
// @ is an alias to /src
import games from "@/components/Games.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "home",
  components: {
    NavBar,
    games,
  },
};
</script>

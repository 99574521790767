<template lang="html">
  <section class="gamepreview">
    <mdb-card>
      <img :src="getImgUrl(image)" alt="Card image cap" />
      <mdb-card-body>
        <mdb-card-title>{{ gameName }}</mdb-card-title>
        <mdb-card-text>{{ describe }}</mdb-card-text>
        <router-link :to="rootLink"
          ><mdb-btn color="danger">Jouer</mdb-btn></router-link
        >
      </mdb-card-body>
    </mdb-card>
  </section>
</template>

<script lang="js">
  import { mdbCard, mdbCardBody, mdbCardTitle, mdbCardText, mdbBtn } from 'mdbvue';
  export default  {
    name: 'gamepreview',
    props: {gameName: String, rootLink: String, image:String},
    components: {
			mdbCard,
			mdbCardBody,
			mdbCardTitle,
			mdbCardText,
			mdbBtn
		},
    mounted(){
     switch(this.gameName) {
       case 'Fruit Ninja':
         this.describe = 'Découpes les fruits';
         break;
      case 'Pong':
         this.describe = 'Défie ton adversaire au ping pong';
         break;
      case 'Chasse aux fruits':
         this.describe = 'Rattrape un maximum de fruit avec Epicio';
         break;
      case 'Ballon Pop':
         this.describe = 'Éclates tous les ballons';
         break;
      case 'Flappy Bird':
         this.describe = 'Attention ne te cogne pas sur les tubes';
         break;
      default:
        break;
     }
    },
    data() {
       return{
        describe: null
      };
    },
    methods: {
      getImgUrl(pic) {
        return require('../assets/'+ pic)
      }
    },


}
</script>

<style scoped lang="scss">
.gamepreview {
}
</style>

<template>
  <span class="game">
    <router-link
      style="position: absolute; transform: translate(-50%, 0); left: 50%"
      to="/"
      ><mdb-btn color="danger">Retour</mdb-btn></router-link
    >
    <iframe class="iframe" v-bind:src="url_Iframe"></iframe>
  </span>
  <!-- <div>{{ url_data }}</div> -->
</template>

<script lang="js">
  import { mdbBtn } from 'mdbvue';

  export default  {
    name: 'game',
    components: {
			mdbBtn
		},
    mounted(){
     this.url_data=this.$route.params.game;
     switch(this.url_data) {
       case 'fruitNinja':
         this.url_Iframe = 'https://fruit-ninja-phaser3.vercel.app';
         break;
      case 'pong':
         this.url_Iframe = 'https://ping-pong-kappa.vercel.app';
         break;
      case 'fruitChase':
         this.url_Iframe = 'https://fruit-chase-phaser-3.vercel.app';
         break;
      case 'ballonPop':
         this.url_Iframe = 'https://ballon-pop-phaser3.vercel.app';
         break;
      case 'flappyBird':
         this.url_Iframe = 'https://flappy-bird-six-sable.vercel.app';
         break;
      default:
        break;
     }
    },
    data()  {
      return{
        url_data: null,
        url_Iframe: null
      };
    }
}
</script>

<style scoped lang="scss">
.iframe {
  overflow: hidden;
  height: 94%;
  width: 100%;
}

#app {
  overflow: hidden;
}
</style>

<template>
  <mdb-navbar style="position: inherit" color="danger" position="top" dark>
    <mdb-navbar-brand></mdb-navbar-brand>
    <mdb-navbar-toggler>
      <mdb-navbar-nav>
        <mdb-nav-item href="/" active>Accueil</mdb-nav-item>
        <!-- <mdb-nav-item href="/jeux">Jeux </mdb-nav-item> -->
      </mdb-navbar-nav>
      <!-- <form>
        <mdb-input
          type="text"
          class="text-white"
          placeholder="Search"
          aria-label="Search"
          label
          navInput
          waves
          waves-fixed
        />
      </form> -->
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  // mdbInput,
} from "mdbvue";

export default {
  name: "NavBar",
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    // mdbInput,
  },
};
</script>

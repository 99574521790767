<template>
  <div id="app" style="overflow-x: hidden">
    <router-view />
  </div>
</template>

<script type="text/javascript">
export default {};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  //margin-top: 150px;
  text-align: center;
  color: #2c3e50;
  height: 100vh !important;
}
</style>

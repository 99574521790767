<template>
  <div>
    <h3>{{ msg }}</h3>
    <div class="row d-flex justify-content-around mb-3">
      <gamepreview
        class="col-md-3 col-sm-12"
        image="fruit-ninja.png"
        gameName="Fruit Ninja"
        rootLink="/jeu/fruitNinja"
      ></gamepreview>
      <gamepreview
        class="col-md-3 col-sm-12"
        image="pong.png"
        gameName="Pong"
        rootLink="/jeu/pong"
      ></gamepreview>
      <gamepreview
        class="col-md-3 col-sm-12"
        image="chase-fruit.png"
        gameName="Chausse aux fruits"
        rootLink="/jeu/fruitChase"
      ></gamepreview>
    </div>
    <div class="row d-flex justify-content-around">
      <gamepreview
        class="col-md-3 col-sm-12"
        image="ballon-pop.png"
        gameName="Ballon Pop"
        rootLink="/jeu/ballonPop"
      ></gamepreview>
      <gamepreview
        class="col-md-3 col-sm-12"
        image="flappy-bird.png"
        gameName="Flappy bird"
        rootLink="/jeu/flappyBird"
      ></gamepreview>
    </div>
  </div>
</template>

<script>
import gamepreview from "./Gamepreview.vue";

export default {
  name: "games",
  props: {
    msg: String,
  },
  components: {
    gamepreview,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import game from '@/views/Game.vue';
import home from '@/views/Home.vue';
Vue.use(Router)

export default new Router({

  routes: [
    {
      path: '/',
      name: 'home',
      component:home,
    },
    // {
    //   path: '/jeux',
    //   name: 'Jeux',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/Games.vue"),
    // },
    {
      path: '/jeu/:game',
      name: 'jeu',
      component: game,
      props: true
    },
  ],
  mode: 'history',
})
